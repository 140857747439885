<template>
  <div id="view-supplier-users">
    <v-container>
      <h1 class="mt-4">Supplier User Details</h1>
      <Alert
        :showAlert="isErrorMessage"
        v-if="isErrorMessage"
        type="error"
        allowDismiss
        absolute
        :text="errorMessage"
        :inPage="false"
        :backgroundColour="true"
      />
      <SupplierUsersForm
        :initial-supplier-user="supplierUser"
        :is-view="true"
        v-if="supplierUser.id"
        @save="updateSupplierUser"
      />
      <Alert
        :showAlert="saveClicked.isClicked"
        :type="saveClicked.type"
        allowDismiss
        absolute
        :text="saveClicked.message"
        :inPage="false"
        :backgroundColour="true"
      />
    </v-container>
  </div>
</template>
<script>
import { Alert } from '@nswdoe/doe-ui-core'
import SupplierUsersForm from '@/views/SupplierUsers/form'

export default {
  name: 'UpdateSupplierUsers',
  title: 'Update Supplier Users - SAIS Operation UI Portal',
  components: {
    Alert,
    SupplierUsersForm,
  },
  data() {
    return {
      supplierUser: {},
      saveClicked: {
        isClicked: false,
        isSuccess: false,
        message: '',
        type: 'success',
      },
    }
  },

  created() {
    this.$store
      .dispatch('moduleSupplierUsers/fetchSupplierUser', {
        userId: this.$route.params.id,
        supplierId: this.$route.query.supplierId,
      })
      .finally(() => {
        this.supplierUser = this.$store.state.moduleSupplierUsers.selectedSupplierUser
      })
  },

  computed: {
    errorMessage() {
      return this.$store.state.moduleSupplierUsers.errorMessage
    },
    isErrorMessage() {
      const errorMessage = this.$store.state.moduleSupplierUsers.errorMessage
      return !!errorMessage && errorMessage.length > 0
    },
  },

  methods: {
    updateSupplierUser(item) {
      this.$store.dispatch('moduleSupplierUsers/putSupplierUser', item).then((response) => {
        this.saveClicked = {
          isClicked: true,
          type: response.isError ? 'error' : 'success',
          isSuccess: !response.isError,
          message: response.isError
            ? response.errorMessage
            : 'The changes have been updated successfully',
        }

        if (!response.isError) {
          this.$router.push('/serviceregistry/supplierUsers')
        }
      })
    },
  },
}
</script>
